export default {
    props: {
        isOpen: {
            default: true,
        },
    },
    data: function() {
        return {
            shown: this.isOpen,
        };
    },
    methods: {
        hide: function() {
            this.shown = false;
        },
        show: function() {
            this.shown = true;
        },
        toggle: function() {
            this.shown = !this.shown;
        },
    },
};
