import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import App from './App.vue'
import router from "./router";
import store from './store'
import utils from './utils'

import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
Vue.use(VueLoading);

import VueSimpleAlert from "vue-simple-alert";
Vue.use(VueSimpleAlert);

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')



const defaultReceptionList = localStorage.getItem("defaultReceptionList");

if (utils.isUndefined(defaultReceptionList)){
  console.log(" -------------- initalize ------------ ");
  store.dispatch('getLists').then(() => {
    const values = store.getters.list_of_lists.filter(x => x.name=='Sample Status')[0].values;
    console.log(values);
    localStorage.setItem("defaultReceptionList", JSON.stringify([...values] ));
    console.log(" -------------- end initalize -------- ");
  });

}



