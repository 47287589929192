import axios from 'axios';
import auth from '../auth';

export default{

    getHeaderToken(){ return auth.auth.getSignInUserSession().getAccessToken().jwtToken; }
    ,
    getUserInfo(){
        const jwtToken = auth.auth.getSignInUserSession().getAccessToken().jwtToken;
        const USERINFO_URL = 'https://'+auth.auth.getAppWebDomain() + '/oauth2/userInfo';
        const requestData = {
            headers: {
                'Authorization': 'Bearer '+ jwtToken
            }
        }
        return axios.get(USERINFO_URL, requestData).then(response => {
            // console.log('/////////////// user info ///////////////')
            // console.log(response.data);
            // console.log('/////////////////////////////////////////')
            return response.data;
        });
    }
}
