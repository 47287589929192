const state = {
    cognitoInfo: {},
    loggedIn: false,
    loadingState: true,
    errorLoadingState: false,
    user: {
        login: 'Guest',
        email: 'g@x',
        roles: {
            isAdmin: false,
            isUser: false,
            isViewer: false
        }
    }
}

function setLoggedIn(newValue) {
    state.loggedIn = newValue;
}

function setLoggedOut() {
    state.loggedIn = false;
    state.cognitoInfo = {};
}

function setCognitoInfo(newValue){
    state.cognitoInfo = newValue;

    const contentAsString = JSON.stringify(state.cognitoInfo);

    state.user.roles.isAdmin = (contentAsString.indexOf(process.env.VUE_APP_AZURE_ADMIN) != -1)? true: false;
    state.user.roles.isUser = (contentAsString.indexOf(process.env.VUE_APP_AZURE_USER) != -1)? true: false;
    state.user.roles.isViewer = (contentAsString.indexOf(process.env.VUE_APP_AZURE_VIEWER) != -1)? true: false;

    state.user.login = newValue["username"];
    state.user.email = newValue["custom:userprincipalname"]
}

export default {
    state: state,
    setLoggedIn: setLoggedIn,
    setLoggedOut: setLoggedOut,
    setCognitoInfo: setCognitoInfo,
    getters: {
        user: state => state.user
    },
}
