import axios from 'axios';
import UserInfoApi from '../api/user';
// import utils from "@/utils";

const state = {
    searchResults: [],
    isFetching: false,
    hasError: false,
    list: {},
    list_of_lists: []
}

export default {
    state,
    getters: {
        list: state => state.lists,
        list_of_lists: state => state.list_of_lists
    },
    mutations: {
        setList(state, l ){
            state.list = l;
        },
        getLists(state, l){
            state.list_of_lists = l.slice();
        }
    },
    actions: {
        saveList(context, payload){

            let list = payload.form;
            const previous_list = payload.previous; // id's from database are here

            if (previous_list._id) { list._id = previous_list._id;  }

            // list.audit = utils.getAudit(list, previous_list, payload.user, payload.reason || '')

            saveList(list).then(function(response){

                if (response.status == 200){
                    context.commit('setList', list);  // trigger the mutation to alert other components
                    context.dispatch('setSuccessMessage','lists saved successfully' )
                }
                else {
                    context.dispatch('setErrorMessage',{ status : response.status, data: response} )
                }
            }).catch(function (error) {
                context.dispatch('setErrorMessage',{ data: error } )
            })


        },
        async getLists ({ commit }) {
            commit('getLists', await getListData())
        }
        /*

          async actionA ({ commit }) {
            commit('gotData', await getData())
          },
          async actionB ({ dispatch, commit }) {
            await dispatch('actionA') // wait for actionA to finish
            commit('gotOtherData', await getOtherData())
          }
         */
    }
}

function getAPI(){

    if (process.env.VUE_APP_INVENTORY_ENDPOINT_GET.indexOf("pmicloud") > 0)
    {
        return process.env.VUE_APP_INVENTORY_ENDPOINT_GET + '?type=lists';
    }
    else {
        return process.env.VUE_APP_INVENTORY_ENDPOINT_GET + '/list/properties'
    }

}

function saveAPI(action){

    if (process.env.VUE_APP_INVENTORY_ENDPOINT_GET.indexOf("pmicloud") > 0)
    {
        return process.env.VUE_APP_INVENTORY_ENDPOINT_SAVE + '?type=lists&action=' + action;
    }
    else {
        return process.env.VUE_APP_INVENTORY_ENDPOINT_SAVE + '/list/' + action;
    }

}

async function getListData(){
    console.log('... getListData called ');
    const jwtToken = await UserInfoApi.getHeaderToken();
    const res = await axios.get(getAPI(),
                                        {
                                            headers: {
                                                'Authorization': 'Bearer '+ jwtToken
                                            },
                                            timeout: 15000
                                        });

    console.log('... getListData data adquired ');

    return res.data.map(v => {
        v.document._id = v.id;
        return v.document;
    });

}

async function saveList(data){
    const jwtToken = await UserInfoApi.getHeaderToken();
    const _headers =  {
        'Authorization': 'Bearer '+ jwtToken ,
        'Content-Type': 'application/json'
    }
    return axios.post(saveAPI('save'), data,{ headers: _headers } );

}
