import axios from "axios";
import UserInfoApi from '../api/user';

const state = {
    searchResults: [],
    isFetching: false,
    hasError: false,
    booking: { /* flavor object to save or load */

    },
    booking_list: [] /* all flavors list from server side */
}

export default {
    state,
    getters: {
        booking: state => state.booking,
        booking_list: state => state.booking_list
    },
    mutations: { // change the state to trigger others
        setBooking(state, bk ){
            state.booking = bk;
            state.saved = true; //check if it;s really needed this property

        },
        getBookingLists(state, l){
            state.booking_list = l.slice();
        }
    },
    actions: {
        saveBooking(context, payload){

            let booking = payload.form;
            const previous_bk = payload.previous; // id's from database are here

            if (previous_bk._id) { booking._id = previous_bk._id;  }

            // booking.audit = utils.getAudit(booking, previous_bk, payload.user, payload.reason || '')

            executeAction('save',booking)
                .then(function(response)
                {

                    if (response.status == 200){
                        context.commit('setBooking', null);  // trigger the mutation to alert other components
                        context.dispatch('setSuccessMessage','booking data delete successfully' )
                    }
                    else {
                        context.dispatch('setErrorMessage',{ status : response.status, data: response} )
                    }
            }).catch(function (error) {
                context.dispatch('setErrorMessage',{ data: error } )
            })


        },
        deleteBooking(context, payload) {
            let booking = payload.form;
            const previous_bk = payload.previous;
            booking._id = previous_bk._id;
            executeAction('delete',booking)
                .then(function(response){
                    if (response.status == 200){
                        context.commit('setBooking', booking);  // trigger the mutation to alert other components
                        context.dispatch('setSuccessMessage','booking data saved successfully' )
                    }
                    else {
                        context.dispatch('setErrorMessage',{ status : response.status, data: response} )
                    }
                }).catch(function (error) {
                context.dispatch('setErrorMessage',{ data: error } )
            })
        },
        saveListOfBookings(context, payload){
            executeAction('save_list', payload)
                .then(function(response){
                    if (response.status == 200){
                        context.dispatch('setSuccessMessage','booking datas from EXCEL saved successfully' );
                    }
                    else {
                        context.dispatch('setErrorMessage',{ status : response.status, data: response} )
                    }
            });
        },
        async getBookingLists ({ commit }) {
            const res =  await getListData()
            commit('getBookingLists', res)
            return res;
        }
    }
}


function getAPI(){

    if (process.env.VUE_APP_INVENTORY_ENDPOINT_GET.indexOf("pmicloud") > 0)
    {
        return process.env.VUE_APP_INVENTORY_ENDPOINT_GET + '?type=bookings';
    }
    else {
        return process.env.VUE_APP_INVENTORY_ENDPOINT_GET + '/booking'
    }
}

function saveAPI(action){

    if (process.env.VUE_APP_INVENTORY_ENDPOINT_SAVE.indexOf("pmicloud") > 0)
    {
        return process.env.VUE_APP_INVENTORY_ENDPOINT_SAVE + '?type=bookings&action=' + action;
    }
    else {
        return process.env.VUE_APP_INVENTORY_ENDPOINT_SAVE + '/booking/' + action
    }

}


async function getListData(){

    const jwtToken = await UserInfoApi.getHeaderToken();
    const res = await axios.get(getAPI(), {
        headers: {
            'Authorization': 'Bearer '+ jwtToken }
    });
    return res.data.rows;

}

async function executeAction(action, o){
    const jwtToken = await UserInfoApi.getHeaderToken();
    const _headers =  {
         'Authorization': 'Bearer '+ jwtToken ,
         'Content-Type': 'application/json'
    }
    return axios.post(saveAPI(action), o,{
       headers: _headers
    } );
}
