<template>
  <div id="topHeader">
    <b-navbar toggleable="lg" type="dark" variant="dark">
      <span class="navbar-brand">&nbsp;
        <b-icon-droplet-half />
        FSM - Flavor Stock Management -
        {{appVersion}}.<span style="font-size: small">{{build}}</span>
      </span>

      <b-navbar-nav>
        <b-nav-item  exact exact-active-class="active" class="buttons" to="/reference"><b-icon-box /> Reference</b-nav-item>
      </b-navbar-nav>

      <b-navbar-nav>
        <b-nav-item  exact exact-active-class="active" class="buttons" to="/reception"><b-icon-inboxes /> Production/Reception</b-nav-item>
      </b-navbar-nav>


      <b-navbar-nav v-if="!user.roles.isViewer">
        <b-nav-item  exact exact-active-class="active" class="buttons" to="/discarding"><b-icon-trash /> Discarding</b-nav-item>
      </b-navbar-nav>


      <b-navbar-nav v-if="user.roles.isAdmin">
        <b-nav-item exact exact-active-class="active" class="list-button" to="/lists"><b-icon-list /> Lists</b-nav-item>
      </b-navbar-nav>


      <User class="user-button navbar-brand" />

    </b-navbar>
  </div>
</template>

<style >

.active {
  color: yellow !important;
  font-weight: bold;
}

.buttons {
  text-align: right;
  font-size: medium !important;
}

.list-button {
  /* bootstrap 5 has problems using right position */
  left: calc( 100vw - 240px)!important;
  top: 10px;
  position: absolute;
  font-size: medium !important;
}

.user-button {
  /* bootstrap 5 has problems using right position */
  left: calc( 100vw - 140px)!important;
  position: absolute;
  font-size: medium !important;
}

</style>
<script>

import { mapState, mapGetters } from 'vuex'
import User from '@/views/components/User'
import {version} from '../../package'
import build from '@/version'

export default {
  name: 'Header',
  data: () => ({
    appVersion:version,
    build: build.rev
  }),
  components: {
    User
  },
  methods: {
    selectedOp(op){
      this.$router.push({ name: op });
    }
  },
  computed: {
    ...mapState(['user']),
    ...mapGetters(['user'])
  }
}
</script>
