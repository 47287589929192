import axios from "axios";
//import utils from "@/utils";
import UserInfoApi from '../api/user';

const state = {
    searchResults: [],
    isFetching: false,
    hasError: false,
    flavor: { /* flavor object to save or load */

    },
    flavor_list: [] /* all flavors list from server side */
}

export default {
    state,
    getters: {
        flavor: state => state.flavor,
        flavor_list: state => state.flavor_list
    },
    mutations: { // change the state to trigger others
        setFlavor(state, flv ){
            state.flavor = flv;
            state.saved = true; //check if it;s really needed this property

        },
        setFlavorLists(state, l){
            state.flavor_list = l.slice();
        }
    },
    actions: { //ajax calls - never update the state
        saveFlavor(context, payload){

            let flavor = payload.form;
            const previous_flv = payload.previous; // id's from database are here

            if (previous_flv._id) {  flavor._id = previous_flv._id; }

            // this is not a validated system, we dont need to be strict moving this part on a server-side
            // flavor.audit = utils.getAudit(flavor, previous_flv, payload.user, payload.reason || '', true)

            executeAction('save', flavor)
                .then(function(response)
                {
                    if (response.status == 200){
                        context.commit('setFlavor', flavor);  // trigger the mutation to alert other components
                        context.dispatch('setSuccessMessage','flavor stock saved successfully' )
                    }
                    else {
                        context.dispatch('setErrorMessage',{ status : response.status, data: response} )
                    }
            }).catch(function (error) {
                context.dispatch('setErrorMessage',{ data: error } )
            })


        },
        async getFlavorLists (context, filter) {
            const res = await getListData(filter);
            context.commit('setFlavorLists', res);
            return res;
        },
        async discardFlavors (context, listOfIdFlavors){

            executeAction('discard', listOfIdFlavors)
                .then(function(response)
                {
                    if (response.status == 200){
                        context.dispatch('setSuccessMessage','flavor stock saved successfully' )
                    }
                    else {
                        context.dispatch('setErrorMessage',{ status : response.status, data: response} )
                    }
            }).catch(function (error) {
                context.dispatch('setErrorMessage',{ data: error } )
            });


        }
    }
}


function getAPI(){

    if (process.env.VUE_APP_INVENTORY_ENDPOINT_GET.indexOf("pmicloud") > 0)
    {
        return process.env.VUE_APP_INVENTORY_ENDPOINT_GET + '?type=flavors';
    }
    else {
        return process.env.VUE_APP_INVENTORY_ENDPOINT_GET + '/flavor/properties'
    }
}

function saveAPI(action=''){

    if (process.env.VUE_APP_INVENTORY_ENDPOINT_GET.indexOf("pmicloud") > 0)
    {
        return process.env.VUE_APP_INVENTORY_ENDPOINT_SAVE + '?type=flavors&action=' + action;
    }
    else {
        return process.env.VUE_APP_INVENTORY_ENDPOINT_SAVE +'/flavor/'+ action;
    }
}


async function getListData(filter){

    const jwtToken = await UserInfoApi.getHeaderToken();
    const res = await axios.post(getAPI() ,filter, {
          headers: {
              'Authorization': 'Bearer '+ jwtToken ,
              'Content-Type': 'application/json'
          }
        }
        );
    return res.data.map(v => {
        v.document._id = v.id;
        return v.document;
    });
}

async function executeAction(action, o){
    const jwtToken = await UserInfoApi.getHeaderToken();
    const _headers =  {
         'Authorization': 'Bearer '+ jwtToken ,
         'Content-Type': 'application/json'
    }
    return axios.post(saveAPI(action), o,{ headers: _headers } );

}


