<template>
  <div>

    <b-button id="tooltip-target-1">
      <b-icon-person/> {{user.email.split('@')[0]}}
    </b-button>
    <b-tooltip target="tooltip-target-1" triggers="hover">
      <table style="width:100px">
        <tr><td>Admin</td>  <td :class="{ 'green-text': user.roles.isAdmin , 'red-text': true}">{{user.roles.isAdmin}}</td></tr>
        <tr><td>User</td>   <td :class="{ 'green-text': user.roles.isUser , 'red-text': true}">{{user.roles.isUser}}</td></tr>
        <tr><td>Viewer</td> <td :class="{ 'green-text': user.roles.isViewer , 'red-text': true}">{{user.roles.isViewer}}</td></tr>
      </table>
    </b-tooltip>

  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.red-text {
  color: red;
}

.green-text {
  color:greenyellow;
}

</style>

<script>

import { mapState, mapGetters } from 'vuex'

export default {
  name: 'User',
  components: {
  },
  computed: {
    ...mapState(['user']),
    ...mapGetters(['user'])
  },
  methods: {
  }
}
</script>

