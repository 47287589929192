<template>

  <span>
    <span  v-for="(msg, index) in messages" :key="index">
      <Message v-bind:message="msg"/>
    </span>
  </span>

</template>

<script>

import Message from "@/views/components/Message";
import {mapActions, mapGetters, mapState} from "vuex";

export default {
  components : {
    Message
  },
  computed: {
    ...mapState(['messages']),
    ...mapGetters(['messages'])
  },
  methods: {
    ...mapActions(['removeMessage'])
  }
}
</script>
