<template>

    <b-alert
        @dismissed="dismissCountDown=0"
        :show="dismissCountDown"
        :variant="message.type"
    >
      {{ message.text }}

      <b-button class="right-button"
                @click="closeIt()"
                variant="close" ></b-button>

    </b-alert>

</template>

<script>

import closable from "@/views/components/mixins/closable";

export default {
  mixins: [closable],
  props : {
    message : Object,
    timeout : Number
  },
  data() {
    return {
      dismissCountDown: this.timeout || 10,
      showDismissibleAlert: true
    }
  },
  methods: {
    closeIt: function (){
      this.hide()
      this.$store.dispatch('removeMessage') //TODO: identify each message and remove by uuid not by the top
    }
  }
}
</script>

<style>
.right-button {
  /* bootstrap 5 has problems using right position */
  left: calc( 100vw - 50px)!important;
  position: absolute;
  background: green;
  padding: 10px;
  border-radius: 5px;
}
</style>
