import Vue from "vue";
import Vuex from "vuex";
import user from '@/store/user'
import lists from '@/store/lists'
import flavors from '@/store/flavors'
import messages from "@/store/messages";
import bookings from "@/store/bookings";

Vue.use(Vuex);

export default new Vuex.Store({
   modules: {
       user,
       lists,
       flavors,
       bookings,
       messages
   }
});
