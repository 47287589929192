import Vue from "vue";
import Router from "vue-router";
import auth from './auth';
import UserInfoStore from './store/user';
import UserInfoApi from './api/user';

// prevent change between routers: https://austingil.com/prevent-browser-refresh-url-changes-route-navigation-vue/

Vue.use(Router);

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

function requireAuth(to, from, next) {

    if (!auth.auth.isUserSignedIn()) {
        UserInfoStore.setLoggedIn(false);
        next({
            path: '/login',
            query: { redirect: to.fullPath }
        });
    } else {
        UserInfoApi.getUserInfo().then(response => {
            UserInfoStore.setLoggedIn(true);
            UserInfoStore.setCognitoInfo(response);
            next();
        });

    }
}

const routeOptions = [
    { path: '/', name: 'Home' , beforeEnter: requireAuth},
    { path: '/reference', name: 'Reference' , beforeEnter: requireAuth},
    { path: '/reception', name: 'Reception' , beforeEnter: requireAuth},
    { path: '/discarding', name: 'Discarding' , beforeEnter: requireAuth},
    { path: '/about', name: 'About' },
    { path: '/lists', name: 'Lists' , beforeEnter: requireAuth},
    { path: '/login', name: 'Login', beforeEnter(){
            const currUrl = window.location.href;
            if (currUrl.indexOf('access_token') > 0) {
                console.log(currUrl);
                auth.auth.parseCognitoWebResponse(currUrl);
            }

            const value = auth.auth.getSession();
            console.log(value);
            console.log('get session returned');
        }
    },
    { path: '/oauth2/authorize', name: 'Authorization', beforeEnter(){
            const currUrl = window.location.href;

            //console.log(currUrl);
            auth.auth.parseCognitoWebResponse(currUrl);
            //next();
        }
    },
    { path: '/logout', name: 'Logout', beforeEnter(to, from, next){
            auth.logout();
            next();
        }

    },
    { path: '*', name: 'PageNotFound', component: PageNotFound},
]


// Fallback page
import PageNotFound from '@/views/pages/PageNotFound'

const routes = routeOptions.map(route => {
    return {
        ...route,
        component: () => import(/* webpackChunkName: "[request]" */ `@/views/pages/${route.name}.vue`)
    }
})


export default new Router({
    mode: 'history',
    routes:  routes
});
