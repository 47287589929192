import utils from "@/utils";

const state = {
    messages: []
}

export default {
    state,
    getters: {
        messages: state => state.messages
    },
    mutations: { // change the state to trigger others
        setSuccessMessage(state, message){
            state.messages.push({ type: 'success' , text: message });
        },
        setErrorMessage(state, message){
            if (utils.isNotUndefined(message)){
                state.messages.push({ type: 'error', text : message.status + ' ' + message.data });
            }
            else{
                state.messages.push({ type: 'error', text : 'Unexpected and Unknown error'});
            }
        },
        removeMessage(state) {
            state.messages.shift();
        }
    },
    actions: { //ajax calls - never update the state
        setSuccessMessage(context, msg){
            context.commit('setSuccessMessage', msg);
        },
        setErrorMessage(context, msg){
            context.commit('setErrorMessage', msg);
        },
        removeMessage(context){
            context.commit('removeMessage');
        }

    }
}
