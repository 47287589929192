<template>
  <div id="app" v-cloak>
    <Header></Header>
    <Messages/>
    <router-view ></router-view>

  </div>
</template>

<script>

import Header from '@/views/Header.vue'
import Messages from '@/views/components/Messages'

export default {
  name: 'App',
  components: {
    Header,
    Messages
  },
  computed: {
    /* getShared: function() { return this.$root.config } */
  },
  methods: {

  }
}

</script>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
  font-size: small;
}

[v-cloak] {
  display:none! important;
}
</style>
