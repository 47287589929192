export default {
    getServerName(){
            let serverName = location.host.substr(0, location.host.indexOf(':'));
            if (serverName.length == 0) {
                serverName = location.host;
            }
            return serverName;
    },

    existProperty(prop) {
        return (  prop != null
            && typeof prop != 'undefined'
            && (
                (typeof prop == 'string' && prop.length > 0 )
                ||
                (typeof prop == 'number')
            )
        );
    },
    isNotUndefined(data){
        return ((typeof data != 'undefined') && data != null);
    },
    isUndefined(data){
        return ((typeof data == 'undefined') || data == null);
    },
    isEmpty(data){
        return (this.isUndefined(data) || data.length == 0 || Object.keys(data).length === 0);
    },
    isBlank(data) {
        if (this.isUndefined(data) || data.length == 0 || Object.keys(data).length === 0) {
            return true;
        }
        else {
            if ((typeof data != 'undefined') && (data != null) && data["_id"] == null) {
                return true;
            }
            else {
                return false;
            }
        }
    },
    isAnObject(data){
        return (data !== null && typeof data == 'object');
    },
    isNumeric(value) {
        return /^-{0,1}\d+[\\.\d+]*$/.test(value);
    },
    isInteger(value) {
        return /^-{0,1}\d+$/.test(value);
    },
    diff(obj1, obj2) {
        const result = {};
        if (Object.is(obj1, obj2)) {
            return undefined;
        }
        if (!obj2 || typeof obj2 !== 'object') {
            return obj2;
        }
        Object.keys(obj1 || {}).concat(Object.keys(obj2 || {})).forEach(key => {
            if(obj2[key] !== obj1[key] && !Object.is(obj1[key], obj2[key])) {
                result[key] = obj2[key];
            }
            if(typeof obj2[key] === 'object' && typeof obj1[key] === 'object') {
                const value = this.diff(obj1[key], obj2[key]);
                if (value !== undefined) {
                    result[key] = value;
                }
            }
        });
        return result;
    },
    getAudit(current, previous, _user, _reason, _addFullObject){

        let result = {...current};

        result.audit = previous.audit || [];

        if (this.isNotUndefined(previous)) {

            if (previous != {})
            {
                for (const key in result) {
                    if (key.startsWith('_')) delete result[key];
                }

                for (const key in previous) {
                    if (key.startsWith('_')) delete previous[key];
                }

                let oBeforeDiff = this.diff(result, previous);
                let oAfterDiff = this.diff(previous, result);

                delete oBeforeDiff.audit; // in case that something was changed\
                delete oAfterDiff.audit;

                let audit = {
                    user : _user,
                    updated : { before : oBeforeDiff, after : oAfterDiff },
                    date : new Date()
                }

                if (this.isNotUndefined(_reason)) audit.reason = _reason;
                if (this.isNotUndefined(_addFullObject)) audit.current = {...current}

                result.audit.push(audit);
            }
        }

        return result.audit;
    },
     range(start, end) {
        return Array(end - start + 1).fill().map((_, idx) => start + idx)
    }
};
